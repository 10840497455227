import Link from "next/link";
const Hero = () => {
  return (
    <div className="relative flex">
      <div className="flex flex-col items-center max-w-screen-xl px-6 mx-auto mb-12 -mt-8 sm:px-6 lg:px-8 lg:flex-row lg:justify-center lg:mb-20">
        <div className="order-last text-center lg:order-first md:text-left lg:pt-10 lg:pb-16">
          <div className="relative md:flex md:flex-col md:items-center">
            <h1
              style={{ zIndex: -1 }}
              className="mt-6 text-4xl font-extrabold leading-10 tracking-tight text-left text-white text-drop-shadow md:text-center sm:text-5xl sm:leading-none md:text-6xl md:mt-0"
            >
              The seamless <br className="lg:hidden" />
              platform <br />
              for independent <br className="lg:hidden" />
              <span className="whitespace-no-wrap">cinema</span>
            </h1>
            <p className="mt-6 text-base text-left text-white text-drop-shadow md:text-center sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
              With a comprehensive package that integrates ticketing, pass
              management, audience data, year-round member relations, digital
              promotions, streaming, online film guides, and balloting into a
              single intelligent platform.
            </p>
            <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
              <div className="rounded-md shadow">
                <Link href="/start">
                  <a className="flex items-center justify-center w-full px-8 py-3 text-base font-medium leading-6 text-white transition duration-150 ease-in-out bg-red-600 border border-transparent rounded-md hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red md:py-4 md:text-lg md:px-10">
                    Get started
                  </a>
                </Link>
              </div>
              <div className="mt-3 sm:mt-0 sm:ml-3">
                <Link href="/start?goal=learn_more&message=I'd like to request a demo of Eventive!">
                  <a className="flex items-center justify-center w-full px-8 py-3 text-base font-medium leading-6 text-white transition duration-150 ease-in-out border border-red-600 rounded-md hover:text-white live-demo-button focus:outline-none focus:shadow-outline-red focus:border-red-300 md:py-4 md:text-lg md:px-10">
                    Live demo
                  </a>
                </Link>
              </div>
            </div>
            <div
              className="flex flex-col gap-2 p-6 my-4 text-center text-gray-800 md:flex-row md:items-center md:justify-center sm:mb-0 sm:mt-8 lg:text-left md:p-6"
              style={{ borderRadius: 6, backgroundColor: "rgba(0,0,0,0.5)" }}
            >
              <span className="text-base font-normal text-white">
                Here to watch a movie?
              </span>
              <a href="https://watch.eventive.org" target="_blank" rel="noreferrer">
                <span className="inline-block w-full px-6 py-1 ml-0 font-medium text-gray-600 transition duration-150 ease-in-out bg-gray-200 rounded-full cursor-pointer md:w-auto md:ml-2 hover:text-gray-700 hover:bg-gray-300 group">
                  Find your channel
                  <svg
                    className="inline-block w-4 h-4 ml-2 transition-all duration-150 ease-in-out transform group-hover:translate-x-1"
                    style={{ verticalAlign: -2 }}
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                      clipRule="evenodd"
                      fillRule="evenodd"
                    ></path>
                  </svg>
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Hero;
