import React from "react";
import useWindowDimensions from "../../../hooks/useWindowDimensions";

function NextIcon({ onClick, className, fill = "#CE3930" }) {
  const { width } = useWindowDimensions();
  return (
    <div onClick={onClick} className={className}>
      <svg
        width={width > 767 ? "42" : "32"}
        height={width > 767 ? "42" : "32"}
        viewBox="0 0 42 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="42" height="42" rx="21" fill={fill} />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.5078 28.5321C18.196 28.2324 18.0208 27.826 18.0208 27.4023C18.0208 26.9785 18.196 26.5721 18.5078 26.2724L23.9851 21.01L18.5078 15.7475C18.3489 15.6001 18.2222 15.4238 18.135 15.2288C18.0479 15.0338 18.002 14.8241 18.0001 14.612C17.9981 14.3998 18.0402 14.1893 18.1239 13.9929C18.2075 13.7965 18.331 13.6181 18.4872 13.4681C18.6433 13.318 18.8291 13.1994 19.0335 13.119C19.2379 13.0387 19.4569 12.9982 19.6778 13.0001C19.8986 13.0019 20.1169 13.046 20.3198 13.1297C20.5227 13.2135 20.7063 13.3352 20.8597 13.4879L27.513 19.8802C27.8248 20.1798 28 20.5862 28 21.01C28 21.4337 27.8248 21.8401 27.513 22.1398L20.8597 28.5321C20.5478 28.8317 20.1248 29 19.6838 29C19.2427 29 18.8197 28.8317 18.5078 28.5321Z"
          fill="white"
        />
      </svg>
    </div>
  );
}

export default NextIcon;
