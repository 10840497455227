import Link from "next/link";
export default function ButtonLink({
  color,
  linkText = "Read the story",
  href = "",
  extraClasses = "",
}) {
  let textClass = "text-gray-600";
  let hoverClass = "hover:text-gray-700";
  switch (color) {
    case "red":
      textClass = "text-red-link";
      hoverClass = "hover:text-red-new";
      break;

    default:
      break;
  }
  if (href) {
    return (
      <Link href={href} as={href}>
        <span
          className={`bg-white rounded-md inline-block w-full text-base lg:text-lg transition duration-150 ease-in-out cursor-pointer md:w-auto group underline ${textClass} ${hoverClass} ${extraClasses}`}
        >
          {linkText}
          <svg
            className="inline-block w-4 h-4 ml-2 transition-all duration-150 ease-in-out transform group-hover:translate-x-1"
            style={{ verticalAlign: -2 }}
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path
              d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
              clipRule="evenodd"
              fillRule="evenodd"
            ></path>
          </svg>
        </span>
      </Link>
    );
  }
  if (!href) {
    return (
      <span
        className={`bg-white rounded-md px-10 py-4 flex items-center text-base lg:text-lg font-bold transition duration-150 ease-in-out cursor-pointer group ${textClass} ${hoverClass} ${extraClasses}`}
        style={{ width: "fit-content" }}
      >
        {linkText}
        <svg
          className="inline-block w-4 h-4 ml-2 transition-all duration-150 ease-in-out transform group-hover:translate-x-1"
          style={{ verticalAlign: -2 }}
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path
            d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
            clipRule="evenodd"
            fillRule="evenodd"
          ></path>
        </svg>
      </span>
    );
  }
}
