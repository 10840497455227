export const growAudienceItems = [
  {
    item: "The joy of access",
    description:
      "Eventive helps you connect audiences more easily to films. Plus we help you connect audiences more effectively to your brand, through targeted messaging tools.",
    image: "/images/features/joy.png",
  },
  {
    item: "A beautiful, seamless audience experience",
    description:
      "Make it easy for people to love your festival with a fully responsive, mobile-friendly, searchable, custom-branded film guide and schedule. Where they can buy tickets and reserve seats in seconds.",
    image: "/images/features/beautiful-experience.png",
  },
  {
    item: "Automated passholder, member, and sponsor benefits",
    description:
      "Create an infinite combination of pass rules to automatically apply benefits – no discount codes required. Allow advance passholder reservations for in-person, online or hybrid events. And give sponsors more visibility than ever.",
    image: "/images/features/automated-benefits.png",
  },
  {
    item: "High fidelity secure streaming",
    description:
      "Eventive is simply the world’s most flexible and secure streaming platform. With every need anticipated – from surround-sound to rights management.",
    image: "/images/features/hi-fi-streaming.png",
  },
];

export const StreamlineOperationsItems = [
  {
    item: "An intuitive organizer interface",
    description:
      "You and your team can manage every aspect of any event – from individual screenings to full blown festival experiences – from a single unified dashboard, from which you can search anything.",
    image: "/images/features/organizer-ui.png",
  },
  {
    item: "Built-in audience support",
    description:
      "Imagine never again having to field tech queries like, “How do I reset my password?” “What was that link again?” “Why isn’t my video playing?” We’ll take care of that for you. Our team will even handle credit card disputes on your organization’s behalf.",
    image: "/images/features/audience-support.png",
  },
  {
    item: "Audience insights to drive marketing",
    description:
      "Eventive offers a robust suite of marketing tools and analytics to help you focus your promotions. Most recently, we’ve added UTM trackers, so you can see where your best responses are coming from, right in your Eventive dashboard.",
    image: "/images/features/audience-insights.png",
  },
  {
    item: "Reporting and analytics at your fingertips",
    description:
      "Above and beyond helping you organize your programs, Eventive helps you manage your organization year-round with operational analytics and real-time data on all your sales.",
    image: "/images/features/reporting-analytics.png",
  },
];

export const suiteItemData = [
  {
    title: "In-Person and Virtual Film Festivals",
    description:
      "Sell passes, tickets, merch, and memberships. Display your program and film guide online and on-site. With fully integrated virtual and physical events.",
    href: "/film-festivals",
  },
  {
    title: "Arthouse Cinema",
    description:
      "Manage all screenings, memberships, concessions and more - via one comprehensive dashboard. Including online and point-of-sale solutions for exhibitors of all sizes.",
    href: "/cinema",
  },
  {
    title: "Distributors",
    description:
      "Easily create your own proprietary VOD screenings, branded affiliate links, promotional events, or even awards screenings. All with built-in reporting and revenue sharing.",
    href: "/distributors",
  },
];

export const reviewsItems = [
  {
    review: `"Eventive has been quietly revolutionizing the independent film industry for years. They saw the need to help filmmakers and festivals with a virtual solution for the future of the industry. Eventive helps keep our indie film ecosystem thriving.”`,
    person: "Melanie Addington",
    org: "Tallgrass Film Association",
  },
  {
    review: `“By working with Eventive, our overall user experience has improved exponentially! The simplification of Memberships has allowed us to have four festivals happen with ZERO system issues.”`,
    person: "Adam Howell",
    org: "Heartland Film Festival",
  },
  {
    review: `“Working with Eventive has been a breeze. The team is always available to talk through potential roadblocks and to help us design workarounds. They provide excellent admin support and have always been responsive and helpful!”`,
    person: "Cassidy Dimon",
    org: "IDA",
  },
  {
    review: `"This was our first time using a system that wasn’t our own proprietary platform. It was amazing and it saved us so much time. At the festival, everyone was super happy – all of our feedback was super positive. I just thought Eventive was very simple for our users.”`,
    person: "Lisa Dreyer",
    org: "Fantastic Fest",
  },
  {
    review: `“I'd struggled to find anything that can handle the nuances of our festival, but Eventive handles them all.”`,
    person: "Alyx Picard-Davis",
    org: "deadCenter Film",
  },
  {
    review: `“When Mountainfilm had to make the quick decision to move our 42nd festival online, Eventive was there to guide us and make the transition as painless as possible. The festival went off without a hitch, and we were pleased to have several thousand new viewers who had never been able to attend the festival in the past, expanding our reach and mission.”`,
    person: "Suzan Beraza",
    org: "Mountainfilm",
  },
];
