import { useCallback, useEffect, useState } from "react";
import useEmblaCarousel from "embla-carousel-react";
import PreviousIcon from "./icons/Previous";
import NextIcon from "./icons/Next";
import CaseStudyCard from "./CaseStudyCard";
import styles from "../styles/CaseStudiesCarousel.module.css";
import { flushSync } from "react-dom";

const TWEEN_FACTOR = 12;

const numberWithinRange = (number, min, max) =>
  Math.min(Math.max(number, min), max);

function CaseStudiesCarousel({ data }) {
  const [viewportRef, embla] = useEmblaCarousel({
    align: "center",
    skipSnaps: true,
    loop: true,
    slidesToScroll: 1,
  });
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState([]);
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);
  const [tweenValues, setTweenValues] = useState([]);

  const scrollTo = useCallback(
    index => embla && embla.scrollTo(index),
    [embla]
  );

  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);

  const onSelect = useCallback(() => {
    if (!embla) return;
    setSelectedIndex(embla.selectedScrollSnap());
    setPrevBtnEnabled(embla.canScrollPrev());
    setNextBtnEnabled(embla.canScrollNext());
  }, [embla]);

  useEffect(() => {
    if (!embla) return;
    onSelect();

    setScrollSnaps(embla.scrollSnapList());

    embla.on("select", onSelect);
  }, [embla, setScrollSnaps, onSelect]);

  return (
    <>
      <div
        className="relative overflow-hidden mx-auto"
        style={{ maxWidth: 1226 }}
      >
        <div className={styles.embla}>
          <div
            className={`${styles.embla__viewport} lg:mx-14`}
            ref={viewportRef}
          >
            <div className={styles.embla__container}>
              {data.map((caseStudy, index) => (
                <CaseStudyCard isCarousel key={index} caseStudy={caseStudy} />
              ))}
            </div>
          </div>
        </div>
        <NextIcon
          className={`z-10 absolute top-1/2 -mt-5 right-0 righ hidden lg:block  ${
            nextBtnEnabled ? "cursor-pointer" : "cursor-not-allowed"
          }`}
          onClick={scrollNext}
          fill={nextBtnEnabled ? "#CE3930" : "#ffffff40"}
        />
        <PreviousIcon
          className={`z-10 absolute top-1/2 -mt-5 hidden lg:block ${
            prevBtnEnabled ? "cursor-pointer" : "cursor-not-allowed"
          }`}
          onClick={scrollPrev}
          fill={prevBtnEnabled ? "#CE3930" : "#ffffff40"}
        />
      </div>
      <div className="z-10 relative flex items-center justify-center gap-2">
        {scrollSnaps.map((_, index) => (
          <div
            className="cursor-pointer embla__dots"
            key={index}
            style={{
              backgroundColor:
                selectedIndex === index
                  ? "rgba(0, 0, 0, 0.75)"
                  : "rgba(0, 0, 0, 0.25)",
            }}
            onClick={() => scrollTo(index)}
          />
        ))}
      </div>
    </>
  );
}

export default CaseStudiesCarousel;
