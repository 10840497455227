import React, { useEffect, useState } from "react";
import PlusIcon from "./icons/Plus";
import MinusIcon from "./icons/Minus";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { motion, AnimatePresence } from "framer-motion";

function Collapse({ row, activeItem, toggle }) {
  const { width } = useWindowDimensions();
  const [open, setOpen] = useState(row.item === activeItem);

  function toggleCollapse() {
    setOpen(!open);
  }

  useEffect(() => {
    if (row.item !== activeItem) {
      setOpen(false);
    }
  }, [row, activeItem]);

  return (
    <div className="flex flex-col min-w-full">
      <hr className="hidden pb-4 border-gray-200 md:block" />
      <div
        className="flex items-center gap-5 cursor-pointer"
        onClick={() => {
          toggle(row.item);
          toggleCollapse();
        }}
      >
        <div className="hidden lg:flex ">
          {open ? (
            <MinusIcon
              fill={activeItem === row.item ? "#CE3930" : "#6B7280"}
              className="rotate-center"
            />
          ) : (
            <PlusIcon
              fill={activeItem === row.item ? "#CE3930" : "#6B7280"}
              className="rotate-center"
            />
          )}
        </div>
        <h4 className={`font-bold text-xl md:text-xl text-dark leading-6`}>
          {row.item}
        </h4>
      </div>

      <AnimatePresence initial={false}>
        {(open || width < 821) && (
          <motion.div
            className="flex flex-col gap-1 overflow-hidden"
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: "auto" },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{
              duration: 0.4,
            }}
          >
            <p
              style={{ maxWidth: 520 }}
              className="pt-2 text-base lg:pl-12 md:text-lg text-gray-link"
            >
              {row.description}
            </p>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default Collapse;
