import Link from "next/link";
const PressReleaseBar = ({ width }) => {
  return (
    <div className="relative flex flex-col items-center gap-2 px-6 py-4 mx-auto bg-yellow-new rounded-2xl boxShadowSpecial max-w-1232">
      <div className="flex flex-col md:flex-row md:items-center md:gap-2">
        <p className="z-10 font-bold text-realeaseBar ">
        Download our new white paper WHAT'S NEXT FOR FILM FESTIVALS: Patterns of Recovery and Growth
        </p>
        <Link href="/whitepaper?utm_source=whitepaper&utm_medium=homepage">
          <span className="z-10 underline transition duration-150 ease-in-out cursor-pointer text-realeaseBar group">
            here
            <span
              className="inline-block w-4 h-4 ml-2 transition-all duration-150 ease-in-out transform text-realeaseBar group-hover:translate-x-1"
              style={{ verticalAlign: -1 }}
            >
              →
            </span>
          </span>
        </Link>
      </div>
    </div>
  );
};
export default PressReleaseBar;
