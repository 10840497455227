import React, { useCallback, useEffect, useState } from "react";
import useEmblaCarousel from "embla-carousel-react";
import Collapse from "./Collapse";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { motion, AnimatePresence } from "framer-motion";
import Head from "next/head";
function CollapseComponent({
  reverse = false,
  title,
  text,
  collapseItems,
  className = "",
}) {
  const { width } = useWindowDimensions();
  const [activeItem, setActiveItem] = useState(collapseItems[0]?.item);
  const [activeItemData, setActiveItemData] = useState([]);
  const [viewportRef, embla] = useEmblaCarousel({
    loop: false,
    dragFree: false,
  });

  function toggleActiveItem(item) {
    setActiveItem(item);
  }

  const handleActiveItemData = useCallback(() => {
    const data = collapseItems.find(item => item.item === activeItem);
    setActiveItemData(data);
  }, [activeItem]);

  useEffect(() => handleActiveItemData(), [handleActiveItemData]);

  return (
    <>
      <Head>
        {collapseItems.map((row, i) => (
          <link key={i} rel="preload" as="image" href={row.image} />
        ))}
      </Head>
      <div
        className={`${className} lg:flex-row flex flex-col-reverse gap-8 mt-8`}
      >
        <div
          className={`${
            reverse ? "lg:flex-row-reverse" : "lg:flex-row"
          } flex flex-col-reverse gap-8`}
        >
          <div className="flex flex-col gap-8 lg:w-1/2">
            {title && text && (
              <div>
                <h3 className="font-extrabold font-28 lg:text-5xl">{title}</h3>
                <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                  {text}
                </p>
              </div>
            )}
            <div ref={width < 1024 ? viewportRef : null}>
              <div className="flex flex-row justify-between w-full min-w-full gap-4 lg:flex-col">
                {collapseItems.map((row, i) => (
                  <div className="flex flex-col gap-8 min-w-90" key={i}>
                    {width < 1024 && (
                      <img
                        className="w-full rounded-lg"
                        src={row.image}
                        alt=""
                      />
                    )}
                    <Collapse
                      row={row}
                      toggle={toggleActiveItem}
                      activeItem={activeItem}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>

          {width > 1024 && activeItemData?.image && (
            <div
              style={{ minHeight: 517 }}
              className={`big-image-${reverse ? "left" : "right"}`}
            >
              <div className="relative overflow-hidden">
                <AnimatePresence initial={false} mode="wait" popLayout>
                  <motion.img
                    className="object-contain"
                    style={{ maxHeight: 517 }}
                    key={activeItemData?.image}
                    src={activeItemData?.image}
                    initial={{ opacity: 0, y: 0 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: "-100%", position: "absolute" }}
                    transition={{ duration: 0.35 }}
                    alt=""
                  />
                </AnimatePresence>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default CollapseComponent;
