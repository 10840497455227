import Layout from "../components/Layout";
import Link from "next/link";
import {
  growAudienceItems,
  reviewsItems,
  StreamlineOperationsItems,
  suiteItemData,
} from "../lib/homePageItems";
import CollapseComponent from "../components/CollapseComponent";
import Hero from "../components/Hero";
import PressReleaseBar from "../components/PressReleaseBar";
import useWindowDimensions from "../hooks/useWindowDimensions";
import ReviewsCarousel from "../components/ReviewsCarousel";
import dynamic from "next/dynamic";
import ContactUsPreFooter from "../components/ContactUsPreFooter";
import { getAllCaseStudies } from "../lib/caseStudies";
import CaseStudiesCarousel from "../components/CaseStudiesCarousel";
const DyamicLogoCarousel = dynamic(() => import("../components/LogoCarousel"), {
  ssr: false,
});

function FeatureSection() {
  const { width } = useWindowDimensions();
  return (
    <section className="relative pt-12 sm:pt-12 bg-gray-50">
      <div className="absolute z-10 w-full px-6 py-4 -mt-8 -top-40">
        <PressReleaseBar width={width} />
      </div>
      <div className="relative max-w-screen-xl px-6 py-8 mx-auto">
        <a id="body-anchor" className="absolute top-8" />
        <div className="mt-2">
          <div>
            <h2 className="mb-12 text-4xl font-extrabold leading-10 tracking-tight text-left text-gray-900 lg:mb-36 md:text-center sm:leading-none md:text-6xl ">
              A better <br className="lg:hidden" />
              experience for your <br />
              audience <br className="lg:hidden" />
              <span className="">and your team</span>
            </h2>
            <CollapseComponent
              title="Grow your audience"
              text="Eventive is designed to create better experiences at every touchpoint. Online or in-person."
              collapseItems={growAudienceItems}
              className="mb-24 xl:mb-48 mb"
            />
            <CollapseComponent
              title="Streamline operations"
              text="Eventive integrates everything into a single intelligent platform. Ticketing, pass management, audience data, year-round member relations, digital promotions, streaming, online film guides, and even balloting. And more."
              collapseItems={StreamlineOperationsItems}
              reverse
            />
          </div>
        </div>
      </div>
    </section>
  );
}

function SuiteItem({ href, title, description }) {
  return (
    <Link href={href}>
      <a className="relative flex flex-col p-6 overflow-hidden transition-all duration-300 ease-in-out transform rounded-lg shadow-lg cursor-pointer group">
        <div className="absolute top-0 left-0 right-0 h-1 transition-all duration-300 ease-in-out bg-red-600 group-hover:h-full" />
        <div className="z-10">
          <h3 className="text-2xl font-bold leading-7 text-gray-900 transition duration-300 ease-in-out group-hover:text-gray-100">
            {title}
          </h3>
          <p className="mt-3 text-base leading-6 text-gray-500 transition duration-300 ease-in-out group-hover:text-gray-200">
            {description}
          </p>
        </div>
      </a>
    </Link>
  );
}

export default function Home({ allCaseStudies }) {
  const { width } = useWindowDimensions();
  const sortedCaseStudies = [...allCaseStudies].sort(
    (a, b) => b.featured - a.featured
  );
  return (
    <Layout isHome>
      <Hero />
      <FeatureSection />
      <section className="py-6 overflow-hidden bg-gray-50 md:py-12 lg:py-18 lg:pb-32">
        <div className="max-w-lg px-6 mx-auto lg:max-w-screen-xl sm:px-6">
          <div className="grid max-w-lg gap-5 mx-auto mt-6 sm:mt-10 lg:grid-cols-3 lg:max-w-screen-xl">
            {suiteItemData.map((item, i) => (
              <SuiteItem
                key={i}
                href={item.href}
                title={item.title}
                description={item.description}
              />
            ))}
          </div>

          <div className="lg:pt-24 w-full">
            <CaseStudiesCarousel data={sortedCaseStudies} />
          </div>
        </div>
      </section>
      <section className="relative flex py-6 overflow-hidden bg-black md:py-12 lg:py-18">
        <div className="flex flex-col items-center justify-end w-full max-w-lg gap-4 mx-auto md:max-w-screen-xl sm:px-6">
          <div
            className="flex flex-col items-center justify-end max-w-lg gap-4 px-4 mx-auto mt-40 text-white md:max-w-screen-xl sm:px-6 bg-image md:mt-96 xl:pt-16"
            style={{
              paddingTop: width > 1280 && `${width / 150}vw`,
            }}
          >
            <h2 className="z-10 font-extrabold text-center font-56">
              Crafted with care <br className="md:hidden" /> by{" "}
              <br className="hidden md:block" /> independent{" "}
              <br className="md:hidden" /> film lovers
            </h2>
            <p className="z-10 text-xl font-normal text-center text-white">
              Eventive started with a question <br className="md:hidden" /> that
              has become our mission. <br className="hidden md:block" /> How can{" "}
              <br className="md:hidden" /> we make independent film more{" "}
              <br className="md:hidden" /> accessible to more people?
            </p>
            <div className="z-10 mt-2 rounded-md shadow ">
              <Link href="/about">
                <a className="flex items-center justify-center w-full px-8 py-3 text-base font-medium leading-6 text-white transition duration-150 ease-in-out bg-red-600 border border-transparent rounded-md hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red md:py-4 md:text-lg md:px-10">
                  Read our answer
                </a>
              </Link>
            </div>
          </div>
          <span className="z-10 mt-16 font-extrabold text-white font-56 md:mb-0 md:mt-24">
            The reviews are in
          </span>
          <ReviewsCarousel data={reviewsItems} />
          <DyamicLogoCarousel
            logos={[
              { asset: "A24", label: "A24" },
              { asset: "AARP", label: "AARP" },
              { asset: "Abramorama", label: "Abramorama" },
              { asset: "AFI", label: "AFI" },
              { asset: "Array", label: "Array" },
              { asset: "AspenFilm", label: "Aspen Film" },
              { asset: "AtlantaFF", label: "Atlanta Film Festival" },
              { asset: "AustinFilmFestival", label: "Austin Film Festival" },
              { asset: "BANFF", label: "BANFF" },
              { asset: "BendFilm", label: "Bend Film" },
              { asset: "BIFA", label: "BIFA" },
              {
                asset: "BigSkyDFF",
                label: "Big Sky Documentary Film Festival",
              },
              {
                asset: "CalgaryIntlFilmFestival",
                label: "Calgary International Film Festival",
              },
              { asset: "CIFF", label: "CIFF" },
              { asset: "CinemaStLouis", label: "Cinema St. Louis" },
              { asset: "CorkFilmFestival", label: "Cork Film Festival" },
              { asset: "DenverFilm", label: "Denver Film" },
              { asset: "DIFF", label: "DIFF" },
              { asset: "DocPoint", label: "DocPoint" },
              { asset: "FantasticFest", label: "FantasticFest" },
              { asset: "FilmPittsburgh", label: "Film Pittsburgh" },
              {
                asset: "GeneSiskelFilmCenter",
                label: "Gene Siskel Film Center",
              },
              { asset: "HeartlandFilm", label: "Heartland Film" },
              { asset: "ICO", label: "ICO" },
              { asset: "IDA", label: "IDA" },
              { asset: "IndieMemphis", label: "Indie Memphis" },
              { asset: "Lionsgate", label: "Lionsgate" },
              { asset: "LunaFest", label: "LunaFest" },
              { asset: "MagnoliaPictures", label: "Magnolia Pictures" },
              { asset: "MontclairFilm", label: "Montclair Film" },
              { asset: "MountainFilm", label: "Mountain Film" },
              { asset: "NewOrleansFF", label: "New Orleans Film Festival" },
              {
                asset: "NewportBeachFilmFest",
                label: "Newport Beach Film Festival",
              },
              { asset: "NordiskPanorama", label: "Nordisk Panorama" },
              { asset: "NorthwestFilmForum", label: "Northwest Film Forum" },
              { asset: "Oscilloscope", label: "Oscilloscope" },
              { asset: "Outfest", label: "Outfest" },
              { asset: "SFF", label: "SFF" },
              { asset: "SFIndieFest", label: "SF Indie Fest" },
              {
                asset: "SheffieldDocFest",
                label: "Sheffield Documentary Festival",
              },
              { asset: "Starz", label: "Starz" },
              { asset: "StrandReleasing", label: "Strand Releasing" },
              { asset: "SundanceInstitute", label: "Sundance Institute" },
              { asset: "Telluride", label: "Telluride" },
            ]} theme="reversed"
          />
        </div>
      </section>
      <ContactUsPreFooter />
    </Layout>
  );
}

export async function getStaticProps() {
  const allCaseStudies = getAllCaseStudies([
    "title",
    "slug",
    "subtitle",
    "indexLinkText",
    "hero",
    "featured",
    "thumbnail",
    "text",
    "subtitleLong",
    "subtitleShort",
  ]);
  return {
    props: { allCaseStudies },
  };
}
