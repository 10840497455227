import PeopleIcon from "./icons/People";
import Link from "next/link";

const ContactUsPreFooter = () => {
  return (
    <section className="flex flex-col items-center justify-center gap-2 px-12 pt-10 pb-16 md:py-20 bg-gray-50">
      <PeopleIcon />
      <h3 className="mb-4 text-3xl font-extrabold leading-none text-center text-black md:text-5xl md:mb-8">
        We’d love to hear from you
      </h3>
      <div className="rounded-md shadow">
        <Link href="/start?goal=learn_more">
          <a className="flex items-center justify-center w-full px-8 py-3 text-base font-medium leading-6 text-white transition duration-150 ease-in-out bg-red-600 border border-transparent rounded-md hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red md:py-4 md:text-lg md:px-10">
            Contact our team
          </a>
        </Link>
      </div>
      <p className="mt-2 text-base font-normal text-center text-gray-500 md:mt-4">
        to schedule a personalized <br className="lg:hidden" /> walk-through of
        the platform.
      </p>
    </section>
  );
};
export default ContactUsPreFooter;
