import React from 'react';

function PlusIcon({ fill, className }) {
  return (
    <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="4" y="11" width="16" height="2" rx="1" fill={fill} />
      <rect x="13" y="4" width="16" height="2" rx="1" transform="rotate(90 13 4)" fill={fill} />
    </svg>

  )
}

export default PlusIcon;