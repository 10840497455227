import React from "react";
import useWindowDimensions from "../../../hooks/useWindowDimensions";

function PreviousIcon({ onClick, className, fill = "#CE3930" }) {
  const { width } = useWindowDimensions();
  return (
    <div onClick={onClick} className={className}>
      <svg
        width={width > 767 ? "42" : "32"}
        height={width > 767 ? "42" : "32"}
        viewBox="0 0 42 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="42" height="42" rx="21" fill={fill} />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.4922 13.4679C23.804 13.7676 23.9792 14.174 23.9792 14.5977C23.9792 15.0215 23.804 15.4279 23.4922 15.7276L18.0149 20.99L23.4922 26.2525C23.6511 26.3999 23.7778 26.5762 23.865 26.7712C23.9521 26.9662 23.998 27.1759 23.9999 27.388C24.0019 27.6002 23.9598 27.8107 23.8761 28.0071C23.7925 28.2035 23.669 28.3819 23.5128 28.5319C23.3567 28.682 23.1709 28.8006 22.9665 28.881C22.7621 28.9613 22.5431 29.0018 22.3222 28.9999C22.1014 28.9981 21.8831 28.954 21.6802 28.8703C21.4772 28.7865 21.2937 28.6648 21.1403 28.5121L14.487 22.1198C14.1752 21.8202 14 21.4138 14 20.99C14 20.5663 14.1752 20.1599 14.487 19.8602L21.1403 13.4679C21.4522 13.1683 21.8752 13 22.3162 13C22.7573 13 23.1803 13.1683 23.4922 13.4679Z"
          fill="white"
        />
      </svg>
    </div>
  );
}

export default PreviousIcon;
