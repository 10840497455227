import React from 'react';
import useWindowDimensions from '../../../hooks/useWindowDimensions';

function PeopleIcon() {
  const { width } = useWindowDimensions()
  return (
    <svg width={width > 767 ? "48" : "25"} height={width > 767 ? "40" : "20"} viewBox="0 0 25 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.5 4C16.5 5.06087 16.0786 6.07828 15.3284 6.82843C14.5783 7.57857 13.5609 8 12.5 8C11.4391 8 10.4217 7.57857 9.67157 6.82843C8.92143 6.07828 8.5 5.06087 8.5 4C8.5 2.93913 8.92143 1.92172 9.67157 1.17157C10.4217 0.421427 11.4391 0 12.5 0C13.5609 0 14.5783 0.421427 15.3284 1.17157C16.0786 1.92172 16.5 2.93913 16.5 4ZM23.1667 6.66667C23.1667 7.37391 22.8857 8.05219 22.3856 8.55228C21.8855 9.05238 21.2072 9.33333 20.5 9.33333C19.7928 9.33333 19.1145 9.05238 18.6144 8.55228C18.1143 8.05219 17.8333 7.37391 17.8333 6.66667C17.8333 5.95942 18.1143 5.28115 18.6144 4.78105C19.1145 4.28095 19.7928 4 20.5 4C21.2072 4 21.8855 4.28095 22.3856 4.78105C22.8857 5.28115 23.1667 5.95942 23.1667 6.66667ZM17.8333 16C17.8333 14.5855 17.2714 13.229 16.2712 12.2288C15.271 11.2286 13.9145 10.6667 12.5 10.6667C11.0855 10.6667 9.72896 11.2286 8.72876 12.2288C7.72857 13.229 7.16667 14.5855 7.16667 16V20H17.8333V16ZM7.16667 6.66667C7.16667 7.37391 6.88572 8.05219 6.38562 8.55228C5.88552 9.05238 5.20724 9.33333 4.5 9.33333C3.79276 9.33333 3.11448 9.05238 2.61438 8.55228C2.11429 8.05219 1.83333 7.37391 1.83333 6.66667C1.83333 5.95942 2.11429 5.28115 2.61438 4.78105C3.11448 4.28095 3.79276 4 4.5 4C5.20724 4 5.88552 4.28095 6.38562 4.78105C6.88572 5.28115 7.16667 5.95942 7.16667 6.66667ZM20.5 20V16C20.5019 14.6444 20.1577 13.3107 19.5 12.1253C20.0911 11.9741 20.709 11.9598 21.3065 12.0836C21.904 12.2075 22.4653 12.4661 22.9476 12.8399C23.4299 13.2136 23.8205 13.6926 24.0895 14.2403C24.3585 14.7879 24.4989 15.3898 24.5 16V20H20.5ZM5.5 12.1253C4.84233 13.3107 4.49813 14.6444 4.5 16V20H0.5V16C0.499743 15.3894 0.639284 14.7868 0.907929 14.2385C1.17657 13.6901 1.56719 13.2106 2.04985 12.8366C2.5325 12.4625 3.09437 12.204 3.69241 12.0807C4.29045 11.9574 4.90878 11.9727 5.5 12.1253Z" fill="#CE3930" />
    </svg>
  )
}

export default PeopleIcon;