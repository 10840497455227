import React, { useCallback, useEffect, useState } from "react";
import useEmblaCarousel from "embla-carousel-react";
import PreviousIcon from "./icons/Previous";
import NextIcon from "./icons/Next";

function ReviewsCarousel({ data }) {
  const [viewportRef, embla] = useEmblaCarousel({
    align: "center",
    skipSnaps: true,
    loop: true,
    slidesToScroll: 1,
  });
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState([]);
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);

  const scrollTo = useCallback(
    index => embla && embla.scrollTo(index),
    [embla]
  );

  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);

  const onSelect = useCallback(() => {
    if (!embla) return;
    setSelectedIndex(embla.selectedScrollSnap());
    setPrevBtnEnabled(embla.canScrollPrev());
    setNextBtnEnabled(embla.canScrollNext());
  }, [embla]);

  useEffect(() => {
    if (!embla) return;
    onSelect();

    setScrollSnaps(embla.scrollSnapList());

    embla.on("select", onSelect);
  }, [embla, setScrollSnaps, onSelect]);

  return (
    <div className="relative overflow-hidden mb-15" style={{ maxWidth: 980 }}>
      <div ref={viewportRef}>
        <div className="flex items-center gap-8 mobile-reviews-screen md:px-20 md:w-full">
          {data.map((review, index) => (
            <div
              key={`${review.review} - ${index}`}
              className="z-10 flex flex-col items-center gap-6 px-4 mb-5"
              style={{ flex: "0 0 100%" }}
            >
              <p className="px-8 text-xl font-normal text-left text-white md:text-3xl md:text-center">
                {review.review}
              </p>
              <div className="flex flex-col">
                <p className="text-base font-medium text-center text-white ">
                  {review.person}
                </p>
                <p className="text-base font-medium text-center text-white opacity-50">
                  {review.org}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <NextIcon
        className={`z-10 absolute top-2/5 right-0 hidden lg:block  ${
          nextBtnEnabled ? "cursor-pointer" : "cursor-not-allowed"
        }`}
        onClick={scrollNext}
        fill={nextBtnEnabled ? "#CE3930" : "#ffffff40"}
      />
      <PreviousIcon
        className={`z-10 absolute top-2/5 hidden lg:block ${
          prevBtnEnabled ? "cursor-pointer" : "cursor-not-allowed"
        }`}
        onClick={scrollPrev}
        fill={prevBtnEnabled ? "#CE3930" : "#ffffff40"}
      />
      <div className="z-10 flex items-center justify-center gap-2">
        {scrollSnaps.map((_, index) => (
          <div
            className="cursor-pointer embla__dots"
            key={index}
            style={{
              backgroundColor:
                selectedIndex === index
                  ? "rgba(255, 255, 255, 0.75)"
                  : "rgba(255, 255, 255, 0.25)",
            }}
            onClick={() => scrollTo(index)}
          />
        ))}
      </div>
    </div>
  );
}

export default ReviewsCarousel;
